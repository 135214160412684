import React, { useEffect, useState } from 'react';

const RemoveWhite = (props) => {

    const json = props.json;

    const [image, setImage] = useState(null);
    const [initialIMG, setInitialIMG] = useState(null);
    const [whiteLevel, setWhiteLevel] = useState(127);
    const [currentWhiteLevel, setCurrentWhiteLevel] = useState(127);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (image && currentWhiteLevel !== whiteLevel) {
            setIsLoading(true);
            setTimeout(() => {
                removeWhite();
            }, 100);
        }
        // eslint-disable-next-line
    }, [whiteLevel]);

    useEffect(() => {
        if (image !== null) {
            const img = document.querySelector('.image-container2 img');
            img.onload = () => {
                removeWhite();
            };
        }
        // eslint-disable-next-line
    }, [image]);

    const removeWhite = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container2 img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
            if (data[i] > whiteLevel && data[i + 1] > whiteLevel && data[i + 2] > whiteLevel) {
                data[i + 3] = 0;
            }
        }
        ctx.putImageData(imageData, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-remove-white.png`;
        link.href = canvas.toDataURL();
        setImage(link.href);
        setCurrentWhiteLevel(whiteLevel);
        setIsLoading(false);
    }

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setInitialIMG(URL.createObjectURL(e.target.files[0]));
    }

    const removeImage = () => {
        setImage(null);
    }

    const downloadImage = () => {
        setIsLoading(true);
        setTimeout(() => {
            handleDownload();
        }, 100);
    }

    const handleDownload = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-remove-white.png`;
        link.href = canvas.toDataURL();
        link.click();
        setImage(link.href);
        setIsLoading(false);
    }

    return (
        <div className="container">
            <div className="image-container">
                {image && <img src={image} alt="remove-white" />}
            </div>
            <div className="image-container2">
                {initialIMG && <img src={initialIMG} alt="remove-white" />}
            </div>
            <div className="control-container">
                <h1>{json.remove_white}</h1>
                <div className="upload">
                    {
                        !image &&
                        <>
                            <input type="file" id="file" accept="image/*" onChange={uploadImage} />
                            <label htmlFor="file">{json.upload_image}</label>
                        </>
                    }
                    {
                        image &&
                        <button onClick={removeImage}>
                            <i className="fa fa-trash"></i>
                            {json.remove_image}
                        </button>
                    }
                </div>
                {
                    image &&
                    <div className="remove-white">
                        <input type="range" min="0" max="255" defaultValue={whiteLevel} onChange={(e) => setWhiteLevel(e.target.value)} />
                    </div>
                }
                {
                    image &&
                    <>
                        {
                            isLoading &&
                            <button className="download" disabled="true">
                                <i className="fa fa-spinner fa-spin"></i>
                                {json.loading}
                            </button>
                        }
                        {
                            !isLoading &&
                            <button className="download" onClick={downloadImage}>
                                <i className="fa fa-download"></i>
                                {json.download}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default RemoveWhite;