const Home = (props) => {
    const json = props.json;
    return (
        <div className="container home normalBG">
            <h1 className="classic_text">ModImage</h1>
            <h3>{json.by_snydrive}</h3>
            <div className="home-container">
                <h2>{json.privacy_title}</h2>
                <p>
                    {json.privacy_content}
                </p>
                <h2>{json.how_title}</h2>
                <p>
                    {json.how_content}
                </p>
                <h2>{json.how_mobile_title}</h2>
                <p>
                    {json.how_mobile_content}
                </p>
                <h2>{json.questions_title}</h2>
                <p>
                    {json.questions_content1}<a href="mailto:sunilgouvi@gmail.com" target="_blank" rel="noreferrer">sunilgouvi@gmail.com</a>{json.questions_content2}
                </p>
                <p>
                    {json.questions_content3}<a href="https://www.facebook.com/sunil.goulamhoussen/" target="_blank" rel="noreferrer">Facebook</a>, <a href="https://www.instagram.com/snydrive/" target="_blank" rel="noreferrer">Instagram</a>, <a href="https://twitter.com/SnyDrive" target="_blank" rel="noreferrer">Twitter</a>, <a href="https://www.linkedin.com/in/sunil-goulamhoussen/" target="_blank" rel="noreferrer">LinkedIn</a>
                </p>
                <p>
                    {json.questions_content4}<a href="https://sunil.fr" target="_blank" rel="noreferrer">www.sunil.fr</a>
                </p>
            </div>
        </div>
    )
}

export default Home;