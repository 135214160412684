import React, { useEffect, useState } from 'react';

const Settings = (props) => {

    const json = props.json;

    const [accessibility, setAccessibility] = useState(localStorage.getItem('modimage-accessibility'));

    const language = localStorage.getItem('modimage-language') || 'en';

    useEffect(() => {
        if (accessibility === "true") {
            document.getElementById('accessibility').checked = true;
            setAccessibility('true');
        }
        else {
            if (accessibility === "false") {
                document.getElementById('accessibility').checked = false;
                setAccessibility('false');
            }
            else {
                localStorage.setItem('modimage-accessibility', 'false');
                document.getElementById('accessibility').checked = false;
                setAccessibility('false');
            }
        }
        // eslint-disable-next-line
    }, []);

    const changeAccessibility = () => {
        const actualAccessibility = localStorage.getItem('modimage-accessibility');
        if (actualAccessibility === 'false') {
            setAccessibility('true');
            localStorage.setItem('modimage-accessibility', 'true');
        }
        else {
            setAccessibility('false');
            localStorage.setItem('modimage-accessibility', 'false');
        }
    }

    useEffect(() => {
        if (accessibility === 'true') {
            document.documentElement.style.setProperty('--dark', '#ffffff');
            document.documentElement.style.setProperty('--light', '#000000');
            document.documentElement.style.setProperty('--light_grey', '#000000');
            document.documentElement.style.setProperty('--dark_grey', '#ffffff');
            document.documentElement.style.setProperty('--accessibility', '#000000');
            document.documentElement.style.setProperty('--accessibility2', '#000000');
            document.documentElement.style.setProperty('--accessibility3', '#f17f14');
        }
        else {
            document.documentElement.style.setProperty('--dark', '#161616');
            document.documentElement.style.setProperty('--light', '#f5f5f5');
            document.documentElement.style.setProperty('--light_grey', '#5a5a5a');
            document.documentElement.style.setProperty('--dark_grey', '#2a2a2a');
            document.documentElement.style.setProperty('--accessibility', '#2a2a2a');
            document.documentElement.style.setProperty('--accessibility2', '#161616');
            document.documentElement.style.setProperty('--accessibility3', '#3a3a3a');
        }
    }, [accessibility]);

    const setNewLanguage = (language) => {
        console.log(language);
        localStorage.setItem('modimage-language', language);
        window.location.reload(1);
    }

    return (
        <div className="container normalBG">
            <h1>{json.settings}</h1>
            <div className="settings-item">
                <p>{json.accessibility}</p>
                <label class="switch">
                    <input type="checkbox" id="accessibility" onChange={changeAccessibility} />
                    <span class="slider round"></span>
                </label>
            </div>
            <div className="settings-item">
                <p>{json.language}</p>
                <select onChange={(e) => setNewLanguage(e.target.value)} defaultValue={language}>
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                    <option value="es">Español</option>
                    <option value="de">Deutsch</option>
                    <option value="it">Italiano</option>
                    <option value="hi">Hindi</option>
                    <option value="ou">Ourdou</option>
                </select>
            </div>
        </div>
    )
}

export default Settings;