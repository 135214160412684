import React, { useEffect, useState } from "react";

const Outlines = (props) => {

    const json = props.json;

    const [image, setImage] = useState(null);
    const [initialIMG, setInitialIMG] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (image !== null) {
            const img = document.querySelector('.image-container2 img');
            img.onload = () => {
                setOutlines();
            };
        }
        // eslint-disable-next-line
    }, [image]);

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setInitialIMG(URL.createObjectURL(e.target.files[0]));
    }

    const removeImage = () => {
        setImage(null);
    }

    function sobelEdgeDetection(data, width, height) {
        // Sobel operator kernels
        var sobelX = [-1, 0, 1, -2, 0, 2, -1, 0, 1];
        var sobelY = [-1, -2, -1, 0, 0, 0, 1, 2, 1];

        var result = new Uint8ClampedArray(data.length);

        for (var y = 1; y < height - 1; y++) {
            for (var x = 1; x < width - 1; x++) {
                var offset = (y * width + x) * 4;
                var pixelX = (
                    sobelX[0] * data[offset - width * 4 - 4] + sobelX[1] * data[offset - width * 4] + sobelX[2] * data[offset - width * 4 + 4] +
                    sobelX[3] * data[offset - 4] + sobelX[4] * data[offset] + sobelX[5] * data[offset + 4] +
                    sobelX[6] * data[offset + width * 4 - 4] + sobelX[7] * data[offset + width * 4] + sobelX[8] * data[offset + width * 4 + 4]
                );

                var pixelY = (
                    sobelY[0] * data[offset - width * 4 - 4] + sobelY[1] * data[offset - width * 4] + sobelY[2] * data[offset - width * 4 + 4] +
                    sobelY[3] * data[offset - 4] + sobelY[4] * data[offset] + sobelY[5] * data[offset + 4] +
                    sobelY[6] * data[offset + width * 4 - 4] + sobelY[7] * data[offset + width * 4] + sobelY[8] * data[offset + width * 4 + 4]
                );

                var magnitude = Math.sqrt(pixelX * pixelX + pixelY * pixelY);

                result[offset] = magnitude;
                result[offset + 1] = magnitude;
                result[offset + 2] = magnitude;
                result[offset + 3] = 255;
            }
        }

        return result;
    }

    const setOutlines = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container2 img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;

        var sobelData = sobelEdgeDetection(data, canvas.width, canvas.height);

        var bordersCanvas = document.createElement('canvas');
        bordersCanvas.width = img.naturalWidth;
        bordersCanvas.height = img.naturalHeight;
        var bordersContext = bordersCanvas.getContext('2d');

        var bordersImageData = bordersContext.createImageData(canvas.width, canvas.height);
        bordersImageData.data.set(sobelData);

        ctx.putImageData(bordersImageData, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-outlines.png`;
        link.href = canvas.toDataURL();
        setImage(canvas.toDataURL());
    }

    const downloadImage = () => {
        setIsLoading(true);
        setTimeout(() => {
            handleDownload();
        }, 100);
    }

    const handleDownload = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-outlines.png`;
        link.href = canvas.toDataURL();
        link.click();
        setImage(canvas.toDataURL());
        setIsLoading(false);
    }


    return (
        <div className="container">
            <div className="image-container">
                {image && <img src={image} alt="outlines" />}
            </div>
            <div className="image-container2">
                {initialIMG && <img src={initialIMG} alt="outlines" />}
            </div>
            <div className="control-container">
                <h1>{json.outlines}</h1>
                <div className="upload">
                    {
                        !image &&
                        <>
                            <input type="file" id="file" accept="image/*" onChange={uploadImage} />
                            <label htmlFor="file">{json.upload_image}</label>
                        </>
                    }
                    {
                        image &&
                        <button onClick={removeImage}>
                            <i className="fa fa-trash"></i>
                            {json.remove_image}
                        </button>
                    }
                </div>
                {
                    image &&
                    <>
                        {
                            isLoading &&
                            <button className="download" disabled="true">
                                <i className="fa fa-spinner fa-spin"></i>
                                {json.loading}
                            </button>
                        }
                        {
                            !isLoading &&
                            <button className="download" onClick={downloadImage}>
                                <i className="fa fa-download"></i>
                                {json.download}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default Outlines;