import React, { useEffect, useState } from 'react';

const Colorize = (props) => {

    const json = props.json;

    const [image, setImage] = useState(null);
    const [initialIMG, setInitialIMG] = useState(null);
    const [myColor, setMyColor] = useState('#DE1F32');
    const [currentColor, setCurrentColor] = useState('#DE1F32');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (image && currentColor !== myColor) {
            setIsLoading(true);
            setTimeout(() => {
                changeColor();
            }, 100);
        }
        // eslint-disable-next-line
    }, [myColor]);

    useEffect(() => {
        if (image !== null) {
            const img = document.querySelector('.image-container2 img');
            img.onload = () => {
                changeColor();
            };
        }
        // eslint-disable-next-line
    }, [image]);

    const changeColor = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container2 img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];

            const myColorRGB = hexToRgb(myColor);

            data[i] = (red + myColorRGB[0]) / 2;
            data[i + 1] = (green + myColorRGB[1]) / 2;
            data[i + 2] = (blue + myColorRGB[2]) / 2;
        }
        ctx.putImageData(imageData, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-colorize${myColor}.png`;
        link.href = canvas.toDataURL();
        setImage(link.href);
        setCurrentColor(myColor);
        setIsLoading(false);
    }

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setInitialIMG(URL.createObjectURL(e.target.files[0]));
    }

    const removeImage = () => {
        setImage(null);
    }

    const hexToRgb = (hex) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return [r, g, b];
    }

    const downloadImage = () => {
        setIsLoading(true);
        setTimeout(() => {
            handleDownload();
        }, 100);
    }

    const handleDownload = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-colorize${myColor}.png`;
        link.href = canvas.toDataURL();
        link.click();
        setIsLoading(false);
    }

    return (
        <div className="container">
            <div className="image-container">
                {image && <img src={image} alt="colorize" />}
            </div>
            <div className="image-container2">
                {initialIMG && <img src={initialIMG} alt="colorize2" />}
            </div>
            <div className="control-container">
                <h1>{json.colorize}</h1>
                <div className="upload">
                    {
                        !image &&
                        <>
                            <input type="file" id="file" accept="image/*" onChange={uploadImage} />
                            <label htmlFor="file">{json.upload_image}</label>
                        </>
                    }
                    {
                        image &&
                        <button onClick={removeImage}>
                            <i className="fa fa-trash"></i>
                            {json.remove_image}
                        </button>
                    }
                </div>
                {
                    image &&
                    <div className="colorize">
                        <input type="color" value={myColor} onChange={(e) => setMyColor(e.target.value)} />
                    </div>
                }
                {
                    image &&
                    <>
                        {
                            isLoading &&
                            <button className="download" disabled="true">
                                <i className="fa fa-spinner fa-spin"></i>
                                {json.loading}
                            </button>
                        }
                        {
                            !isLoading &&
                            <button className="download" onClick={downloadImage}>
                                <i className="fa fa-download"></i>
                                {json.download}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default Colorize;