import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Settings from './components/Settings';
import Transparency from './components/Transparency';
import BlackWhite from './components/BlackWhite';
import Sepia from './components/Sepia';
import Outlines from './components/Outlines';
import Blur from './components/Blur';
import Grayscale from './components/Grayscale';
import Colorize from './components/Colorize';
import Invert from './components/Invert';
import RemoveWhite from './components/RemoveWhite';
import RemoveBlack from './components/RemoveBlack';
import RemoveBackground from './components/RemoveBackground';

import en from './languages/en.json';
import fr from './languages/fr.json';
import es from './languages/es.json';
import de from './languages/de.json';
import it from './languages/it.json';
import hi from './languages/hi.json';
import ou from './languages/ou.json';

function App() {
  const accessibility = localStorage.getItem('modimage-accessibility');
  const language = localStorage.getItem('modimage-language');

  const lang = language === 'en' ? en : language === 'fr' ? fr : language === 'es' ? es : language === 'de' ? de : language === 'it' ? it : language === 'hi' ? hi : language === 'ou' ? ou : en;

  useEffect(() => {
    if (accessibility === 'true') {
      document.documentElement.style.setProperty('--dark', '#ffffff');
      document.documentElement.style.setProperty('--light', '#000000');
      document.documentElement.style.setProperty('--light_grey', '#000000');
      document.documentElement.style.setProperty('--dark_grey', '#ffffff');
    }
    else {
      document.documentElement.style.setProperty('--dark', '#161616');
      document.documentElement.style.setProperty('--light', '#f5f5f5');
      document.documentElement.style.setProperty('--light_grey', '#5a5a5a');
      document.documentElement.style.setProperty('--dark_grey', '#2a2a2a');
    }
  }, [accessibility]);

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar json={lang} />
        <Routes>
          <Route path="/" element={<Home json={lang} />} />
          <Route path="/settings" element={<Settings json={lang} />} />
          <Route path="transparency" element={<Transparency json={lang} />} />
          <Route path="blackwhite" element={<BlackWhite json={lang} />} />
          <Route path="outlines" element={<Outlines json={lang} />} />
          <Route path="blur" element={<Blur json={lang} />} />
          <Route path="sepia" element={<Sepia json={lang} />} />
          <Route path="grayscale" element={<Grayscale json={lang} />} />
          <Route path="colorize" element={<Colorize json={lang} />} />
          <Route path="invert" element={<Invert json={lang} />} />
          <Route path="removewhite" element={<RemoveWhite json={lang} />} />
          <Route path="removeblack" element={<RemoveBlack json={lang} />} />
          <Route path="removebackground" element={<RemoveBackground json={lang} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
