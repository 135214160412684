import React, { useEffect, useState } from 'react';

const RemoveBackground = (props) => {

    const json = props.json;

    const [image, setImage] = useState(null);
    const [initialIMG, setInitialIMG] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const variation = 19;

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setInitialIMG(URL.createObjectURL(e.target.files[0]));
    }

    const removeImage = () => {
        setImage(null);
    }

    useEffect(() => {
        if (initialIMG !== null) {
            const img = document.querySelector('.image-container2 img');
            img.onload = () => {
                removeBackground();
            };
        }
        // eslint-disable-next-line
    }, [initialIMG]);

    const removeBackground = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;

        // Iterate through the pixel data and identify the outlines
        for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];

            if (i < canvas.width * 4 || i > data.length - canvas.width * 4) {
                data[i + 3] = 0; // Alpha
            }

            // Compare the pixel with its neighbors to identify the outlines
            const neighbors = [
                data[i - 4], // left
                data[i + 4], // right
                data[i - canvas.width * 4], // above
                data[i + canvas.width * 4], // below
            ];

            const isOutline = neighbors.some(
                (neighbor) =>
                    neighbor === undefined || // Check if neighbor is undefined (at the edge)
                    Math.abs(neighbor - red) > variation || // Check for significant color difference
                    Math.abs(neighbor - green) > variation ||
                    Math.abs(neighbor - blue) > variation
            );

            const alpha = 235;

            if (!isOutline && red > alpha && green > alpha && blue > alpha) {
                //   // Mark the outline pixel
                //   data[i] = 255; // Red
                //   data[i + 1] = 0; // Green
                //   data[i + 2] = 0; // Blue
                //   data[i + 3] = 255; // Alpha
                // } else {
                //   // Make the pixel transparent
                data[i + 3] = 0; // Alpha
            }
        }

        for (let i = 0; i < data.length; i += 4) {
            var percent = 0;
            if (data[i - 1] === 0) {
                percent++;
            }
            if (data[i - 5] === 0) {
                percent++;
            }
            if (data[i - canvas.width + 3] === 0) {
                percent++;
            }
            if (data[i - canvas.width * 2 + 3] === 0) {
                percent++;
            }
            if (data[i + 7] === 0) {
                percent++;
            }
            if (data[i + 11] === 0) {
                percent++;
            }
            if (data[i + canvas.width + 3] === 0) {
                percent++;
            }
            if (data[i + canvas.width * 2 + 3] === 0) {
                percent++;
            }
            if (percent >= 6 && data[i] > 230 && data[i + 1] > 230 && data[i + 2] > 230) {
                data[i + 3] = 0; // Alpha
            }
        }

        for (let i = 0; i < data.length; i += 4) {
            if (data[i] > 250 && data[i + 1] > 250 && data[i + 2] > 250) {
                if (data[i + 4] > 230 && data[i + 5] > 230 && data[i + 6] > 230 && data[i + 8] > 230 && data[i + 9] > 230 && data[i + 10] > 230) {
                    data[i + 3] = 0;
                }
            }
        }

        const canvas2 = document.createElement('canvas');
        canvas2.width = img.naturalWidth;
        canvas2.height = img.naturalHeight;
        const ctx2 = canvas.getContext('2d');
        ctx2.drawImage(img, 0, 0);
        const imageData2 = ctx2.getImageData(0, 0, canvas2.width, canvas2.height);
        const data2 = imageData2.data;

        for (let i = 0; i < canvas.height; i++) {
            var tab = [];
            for (let j = 0; j < canvas.width; j++) {
                var index = (i * canvas.width + j) * 4;
                if (data[index + 3] !== 0) {
                    tab.push(j);
                }
            }
            if (tab.length > 0) {
                for (let a = 0; a < tab[0]; a++) {
                    data2[(i * canvas.width + a) * 4 + 3] = 0;
                }
                for (let a = tab[tab.length - 1]; a < canvas.width; a++) {
                    data2[(i * canvas.width + a) * 4 + 3] = 0;
                }
                // for (let j = tab[0]; j < tab[tab.length - 1]; j++) {
                //   data[index] = data2[index];
                //   data[index + 1] = data2[index + 1];
                //   data[index + 2] = data2[index + 2];
                //   data[index + 3] = data2[index + 3];
                // }
            }
            else {
                for (let j = 0; j < canvas.width; j++) {
                    data2[(i * canvas.width + j) * 4 + 3] = 0;
                }
            }
        }

        ctx.putImageData(imageData2, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-remove-black.png`;
        link.href = canvas.toDataURL();
        setImage(link.href);
    }

    const downloadImage = () => {
        setIsLoading(true);
        setTimeout(() => {
            handleDownload();
        }, 100);
    }

    const handleDownload = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-remove-background.png`;
        link.href = canvas.toDataURL();
        setImage(link.href);
        link.click();
        setIsLoading(false);
    }

    return (
        <div className="container">
            <div className="image-container">
                {image && <img src={image} alt="remove-background" />}
            </div>
            <div className="image-container2">
                {initialIMG && <img src={initialIMG} alt="remove-background" />}
            </div>
            <div className="control-container">
                <h1>{json.remove_background}</h1>
                <div className="upload">
                    {
                        !image &&
                        <>
                            <input type="file" id="file" accept="image/*" onChange={uploadImage} />
                            <label htmlFor="file">{json.upload_image}</label>
                        </>
                    }
                    {
                        image &&
                        <button onClick={removeImage}>
                            <i className="fa fa-trash"></i>
                            {json.remove_image}
                        </button>
                    }
                </div>
                {
                    image &&
                    <>
                        {
                            isLoading &&
                            <button className="download" disabled="true">
                                <i className="fa fa-spinner fa-spin"></i>
                                {json.loading}
                            </button>
                        }
                        {
                            !isLoading &&
                            <button className="download" onClick={downloadImage}>
                                <i className="fa fa-download"></i>
                                {json.download}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default RemoveBackground;