import React, { useEffect, useState } from 'react';

const BlackWhite = (props) => {

    const json = props.json;

    const [image, setImage] = useState(null);
    const [initialIMG, setInitialIMG] = useState(null);
    const [limit, setLimit] = useState(127);
    const [currentLimit, setCurrentLimit] = useState(127);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (image && currentLimit !== limit) {
            setIsLoading(true);
            setTimeout(() => {
                setCurrentLimit(limit);
                changeThreshold();
            }, 100);
        }
        // eslint-disable-next-line
    }, [limit]);

    useEffect(() => {
        if (image !== null) {
            const img = document.querySelector('.image-container2 img');
            img.onload = () => {
                changeThreshold();
            };
        }
        // eslint-disable-next-line
    }, [image]);

    const changeThreshold = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container2 img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];
            const average = Math.floor((red + green + blue) / 3);
            if (average < limit) {
                data[i] = 0;
                data[i + 1] = 0;
                data[i + 2] = 0;
            }
            else {
                data[i] = 255;
                data[i + 1] = 255;
                data[i + 2] = 255;
            }
        }
        ctx.putImageData(imageData, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-blackwhite.png`;
        link.href = canvas.toDataURL();
        setImage(link.href);
        setIsLoading(false);
    }

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setInitialIMG(URL.createObjectURL(e.target.files[0]));
    }

    const removeImage = () => {
        setImage(null);
    }

    const downloadImage = () => {
        setIsLoading(true);
        setTimeout(() => {
            handleDownload();
        }, 100);
    }

    const handleDownload = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-blackwhite.png`;
        link.href = canvas.toDataURL();
        link.click();
        setIsLoading(false);
    }


    return (
        <div className="container">
            <div className="image-container">
                {image && <img src={image} alt="blackwhite" />}
            </div>
            <div className="image-container2">
                {initialIMG && <img src={initialIMG} alt="blackwhite2" />}
            </div>
            <div className="control-container">
                <h1>{json.black_white}</h1>
                <div className="upload">
                    {
                        !image &&
                        <>
                            <input type="file" id="file" accept="image/*" onChange={uploadImage} />
                            <label htmlFor="file">{json.upload_image}</label>
                        </>
                    }
                    {
                        image &&
                        <button onClick={removeImage}>
                            <i className="fa fa-trash"></i>
                            {json.remove_image}
                        </button>
                    }
                </div>
                {
                    image &&
                    <div className="threshold">
                        <input type="range" min="0" max="255" step="1" defaultValue={127} onChange={(e) => setLimit(e.target.value)} />
                    </div>
                }
                {
                    image &&
                    <>
                        {
                            isLoading &&
                            <button className="download" disabled="true">
                                <i className="fa fa-spinner fa-spin"></i>
                                {json.loading}
                            </button>
                        }
                        {
                            !isLoading &&
                            <button className="download" onClick={downloadImage}>
                                <i className="fa fa-download"></i>
                                {json.download}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default BlackWhite;