import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../images/logo.png';

const Navbar = (props) => {

    const json = props.json;

    const navigate = useNavigate();

    // Get the current path
    const path = window.location.pathname.split('/')[1];

    // Set the current path as active
    useEffect(() => {
        const navButtons = document.querySelectorAll('.nav-button');
        navButtons.forEach(button => {
            if (button.value.toLowerCase() === path) {
                button.classList.add('nav-button-active');
            } else {
                button.classList.remove('nav-button-active');
            }
        })
    }, [path]);

    return (
        <div className="navbar">
            <div className="logo" onClick={() => navigate('/')}>
                <h1>ModImage</h1>
                <img src={logo} alt="remove.bg logo" />
            </div>
            <button className="nav-button" value="transparency" onClick={() => navigate('transparency')}>
                <i className="fa fa-tint"></i>
                <span>{json.transparency}</span>
            </button>
            <button className="nav-button" value="blackwhite" onClick={() => navigate('blackwhite')}>
                <i className="fa fa-adjust"></i>
                <span>{json.black_white}</span>
            </button>
            <button className="nav-button" value="outlines" onClick={() => navigate('outlines')}>
                <i className="fa fa-pencil"></i>
                <span>{json.outlines}</span>
            </button>
            <button className="nav-button" value="grayscale" onClick={() => navigate('grayscale')}>
                <label>G</label>
                <span>{json.grayscale}</span>
            </button>
            <button className="nav-button" value="colorize" onClick={() => navigate('colorize')}>
                <i className="fa fa-paint-brush"></i>
                <span>{json.colorize}</span>
            </button>
            <button className="nav-button" value="blur" onClick={() => navigate('blur')}>
                <label>B</label>
                <span>{json.blur}</span>
            </button>
            <button className="nav-button" value="invert" onClick={() => navigate('invert')}>
                <label>I</label>
                <span>{json.invert}</span>
            </button>
            <button className="nav-button" value="sepia" onClick={() => navigate('sepia')}>
                <label>S</label>
                <span>{json.sepia}</span>
            </button>
            <button className="nav-button" value="removewhite" onClick={() => navigate('removewhite')}>
                <i className="fa fa-eraser"></i>
                <span>{json.remove_white}</span>
            </button>
            <button className="nav-button" value="removeblack" onClick={() => navigate('removeblack')}>
                <i className="fa fa-eraser"></i>
                <span>{json.remove_black}</span>
            </button>
            <button className="nav-button" value="removebackground" onClick={() => navigate('removebackground')}>
                <i className="fa fa-eraser"></i>
                <span>{json.remove_background}</span>
            </button>
            <button className="nav-button settings" value="settings" onClick={() => navigate('settings')}>
                <i className="fa fa-cog"></i>
                <span>{json.settings}</span>
            </button>
        </div>
    )
}

export default Navbar;