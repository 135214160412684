import React, { useState } from 'react';

const Transparency = (props) => {

    const json = props.json;

    const [image, setImage] = useState(null);
    const [opacity, setOpacity] = useState(0.5);
    const [isLoading, setIsLoading] = useState(false);

    const changeOpacity = (e) => {
        setOpacity(e.target.value);
    }

    const uploadImage = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
    }

    const removeImage = () => {
        setImage(null);
    }

    const downloadImage = () => {
        setIsLoading(true);
        setTimeout(() => {
            handleDownload();
        }, 100);
    }

    const handleDownload = () => {
        const canvas = document.createElement('canvas');
        const img = document.querySelector('.image-container img');
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        canvas.width = width;
        canvas.height = height;
        const imageName = image.split('/').pop().split('.')[0];
        const ctx = canvas.getContext('2d');
        ctx.filter = `opacity(${opacity})`;
        ctx.drawImage(img, 0, 0);
        const link = document.createElement('a');
        link.download = `${imageName}-transparency.png`;
        link.href = canvas.toDataURL();
        link.click();
        setIsLoading(false);
    }

    return (
        <div className="container">
            <div className="image-container">
                {image && <img src={image} alt="transparency" style={{ opacity: opacity }} />}
            </div>
            <div className="control-container">
                <h1>{json.transparency}</h1>
                <div className="upload">
                    {
                        !image &&
                        <>
                            <input type="file" id="file" accept="image/*" onChange={uploadImage} />
                            <label htmlFor="file">{json.upload_image}</label>
                        </>
                    }
                    {
                        image &&
                        <button onClick={removeImage}>
                            <i className="fa fa-trash"></i>
                            {json.remove_image}
                        </button>
                    }
                </div>
                {
                    image &&
                    <div className="opacity">
                        <input type="range" min="0" max="1" step="0.05" value={opacity} onChange={changeOpacity} />
                    </div>
                }
                {
                    image &&
                    <>
                        {
                            isLoading &&
                            <button className="download" disabled="true">
                                <i className="fa fa-spinner fa-spin"></i>
                                {json.loading}
                            </button>
                        }
                        {
                            !isLoading &&
                            <button className="download" onClick={downloadImage}>
                                <i className="fa fa-download"></i>
                                {json.download}
                            </button>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default Transparency;